.text-color-0 {
    color: #8884d8;
}
.text-color-1 {
    color: #AF7AC5;
}
.text-color-2 {
    color: #D98880;
}
.text-color-3 {
    color: #9B59B6;
}
.text-color-4 {
    color: #7FB3D5;
}
.text-color-5 {
    color: #5DADE2;
}
.text-color-6 {
    color: #7DCEA0;
}
.text-color-7 {
    color: #D4AC0D;
}
.text-color-8 {
    color: #5D6D7E;
}
.text-color-9 {
    color: #E67E22;
}
.text-color-10 {
    color: #73C6B6;
}
.text-color-11 {
    color: #F8C471;
}
.text-color-12 {
    color: #17A589;
}
.text-color-13 {
    color: #FF7373;
}
.text-color-14 {
    color: #0A5A59;
}
.text-color-15 {
    color: #FFC3A0;
}


.bg-color-0 {
    background-color: #8884d8;
}
.bg-color-1 {
    background-color: #AF7AC5;
}
.bg-color-2 {
    background-color: #D98880;
}
.bg-color-3 {
    background-color: #9B59B6;
}
.bg-color-4 {
    background-color: #7FB3D5;
}
.bg-color-5 {
    background-color: #5DADE2;
}
.bg-color-6 {
    background-color: #7DCEA0;
}
.bg-color-7 {
    background-color: #D4AC0D;
}
.bg-color-8 {
    background-color: #5D6D7E;
}
.bg-color-9 {
    background-color: #E67E22;
}
.bg-color-10 {
    background-color: #73C6B6;
}
.bg-color-11 {
    background-color: #F8C471;
}
.bg-color-12 {
    background-color: #17A589;
}
.bg-color-13 {
    background-color: #FF7373;
}
.bg-color-14 {
    background-color: #0A5A59;
}
.bg-color-15 {
    background-color: #FFC3A0;
}