.toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #D1DBD8;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  margin-top:3px;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: -4px;
  top: -2px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border:solid 1px #D1DBD8;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  background-color: #fff;
  border:solid 1px #4994EB;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #4994EB;
}
.toggle-switch .label{
  padding-left: 45px;
  cursor: pointer;
  color: #5D6D7E;
}